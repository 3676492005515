<template>
    <div class="doc-container">
        <CTabs :active-tab.sync="activeTab">
            <CTab>
                <template slot="title">Masuk</template>
                <div class="mt-20">
                    <agenda-chart type-prop="Masuk"/>
                </div>
            </CTab>
            <CTab>
                <template slot="title">Keluar</template>
                <div class="mt-20">
                    <agenda-chart type-prop="Keluar"/>
                </div>
            </CTab>
            <CTab>
                <template slot="title">Nota Dinas</template>
                <div class="mt-20">
                    <agenda-chart type-prop="Nota Dinas"/>
                </div>
            </CTab>
        </CTabs>
    </div>
</template>

<script>
import AgendaChart from '@/views/chart/AgendaChart.vue'
export default {
    components: {
        'agenda-chart': AgendaChart,
    },
    data () {
        return {
            activeTab: 0
        }
    }
}
</script>